import { graphql, ChildMutateProps } from 'react-apollo'

import TOGGLE_BYPASS_SPAM from '../../../gql/mutations/toggleBypassSpam.gql'

import { UserDigest } from '../../../definitions'

type Response = {
  putUserFeatureFlags: UserDigest
}

type InputProps = {
  users: UserDigest[]
  enabled: boolean
  callback?: () => void
}

type Variables = {
  input: {
    ids: string[]
    flags: string[]
  }
}

export type ChildProps = ChildMutateProps<InputProps, Response, Variables>

const withToggleBypassSpam = graphql<
  InputProps,
  Response,
  Variables,
  ChildProps
>(TOGGLE_BYPASS_SPAM)

export default withToggleBypassSpam
