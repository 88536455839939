import * as React from 'react'
import { Table } from 'antd'
import _compact from 'lodash/compact'

import DateTime from '../../DateTime'
import UserLink from '../../User/Link'
import ArticleLink from '../Link'
import SetBoost from '../../SetBoost'
import MarkSpam from '../MarkSpam'
import ArticleStateTag from '../StateTag'

import { ArticleDigest } from '../../../definitions'
import { PAGE_SIZE } from '../../../constants'
import { getCurrentPaginationFromUrl, onPaginationChange } from '../../../utils'
import LevelTag, { LevelEnum } from '../../LevelTag'

type ArticleDigestListProps = {
  data: ArticleDigest[]
  spamThreshold?: number | null
  loading?: boolean
  pagination?: {
    totalCount: number
    pageSize?: number
    fetchMore?: any
    variables?: any
  }
  recommend?: {
    icymi?: boolean
    hottest?: boolean
    newest?: boolean
    topic?: boolean
  }
}

class ArticleDigestList extends React.Component<ArticleDigestListProps> {
  private _renderTitleCell(_: any, record: ArticleDigest): React.ReactNode {
    return <ArticleLink id={record.id} title={record.title} />
  }

  public render() {
    const {
      data,
      loading = false,
      pagination,
      recommend,
      spamThreshold,
    } = this.props
    const currentPagination = getCurrentPaginationFromUrl()

    return (
      <Table<ArticleDigest>
        bordered
        loading={loading}
        dataSource={_compact(data)}
        scroll={{ x: 1200, y: '70vh' }}
        pagination={
          pagination
            ? {
                defaultCurrent: currentPagination && currentPagination.page,
                pageSize: pagination.pageSize || PAGE_SIZE,
                total: pagination.totalCount,
                onChange: (page) => onPaginationChange({ pagination, page }),
                showTotal: (t) => `共 ${t} 項`,
                position: 'both',
              }
            : false
        }
        rowKey={(record) => record.id}
      >
        <Table.Column<ArticleDigest>
          dataIndex="title"
          title="標題"
          render={this._renderTitleCell}
        />

        <Table.Column<ArticleDigest>
          dataIndex="author"
          title="作者"
          width={200}
          render={(author) => (
            <UserLink
              id={author.id}
              userName={author.userName}
              displayName={author.displayName}
              featureFlags={author.oss.featureFlags}
            />
          )}
        />
        <Table.Column<ArticleDigest>
          dataIndex="oss.spamStatus.score"
          title="垃圾文概率"
          width={120}
          render={(score) => {
            const isSpam = spamThreshold && score >= spamThreshold

            if (score === null) {
              return <LevelTag level={LevelEnum.INFO}>計算中</LevelTag>
            }

            return (
              <LevelTag level={isSpam ? LevelEnum.ERROR : LevelEnum.SUCCESS}>
                {score.toFixed(3)}
              </LevelTag>
            )
          }}
        />
        <Table.Column<ArticleDigest>
          dataIndex="oss.spamStatus.isSpam"
          title="標記垃圾文"
          width={120}
          render={(isSpam, record) => (
            <MarkSpam isSpam={isSpam} articleId={record.id} />
          )}
        />
        <Table.Column<ArticleDigest>
          dataIndex="state"
          title="狀態"
          width={100}
          render={(state) => <ArticleStateTag state={state} />}
        />
        <Table.Column<ArticleDigest>
          dataIndex="commentCount"
          width={100}
          title="評論數"
        />
        <Table.Column<ArticleDigest>
          dataIndex="createdAt"
          title="時間"
          width={200}
          render={(createdAt) => <DateTime date={createdAt} />}
        />
        {recommend && recommend.topic && (
          <Table.Column<ArticleDigest>
            dataIndex="oss.boost"
            title="Boost"
            width={150}
            render={(boost, record) => (
              <SetBoost boost={boost} id={record.id} type="Article" />
            )}
          />
        )}
      </Table>
    )
  }
}

export default ArticleDigestList
