import * as React from 'react'
import { Link } from 'react-router-dom'

import { PATH } from '../../../constants'
import LevelTag, { LevelEnum } from '../../LevelTag'

type UserLinkProps = {
  id: string
  userName: string
  displayName: string
  featureFlags?: {
    type: string
    createdAt: string | Date
  }[]
}

const UserLink: React.FunctionComponent<UserLinkProps> = ({
  id,
  userName,
  displayName,
  featureFlags,
}) => {
  const to = PATH.USER_DETAIL.replace(':id', id)
  const isBypassSpamDetection = featureFlags?.some(
    (flag) => flag.type === 'bypassSpamDetection'
  )

  return (
    <>
      <Link to={to}>
        {displayName} (@{userName})
      </Link>

      <section style={{ marginTop: 4 }}>
        {isBypassSpamDetection && (
          <LevelTag level={LevelEnum.SUCCESS}>Spam 免檢</LevelTag>
        )}
      </section>
    </>
  )
}

export default UserLink
